function dialogify(message, input_options) {

    var dialog = $("<div>" + message + "</div>");

    var options = {
        maxHeight: 480,
        width: 480,
        classes: {
            "ui-dialog": "dialog",
            "ui-dialog-titlebar": "dialog__titlebar",
            "ui-dialog-buttonpane": "dialog__buttons",
            "ui-dialog-content": "dialog__text",
            "ui-dialog-buttonset": "dialog__buttons-inner"
        },
        show: {effect: 'fade', duration: 250},
        hide: {effect: 'fade', duration: 250},
        closeText: "hide",
        draggable: false,
        resizable: false,
        autoOpen: true,
        modal: true,
        buttons: {
            'OK': function () {
                $(this).dialog("close");
            }
        },
        close: function () {
            $(this).remove();
        }
    };

    $.extend(options, input_options);

    $(dialog).dialog(options);
}
