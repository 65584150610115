$(document).ready(function () {

    $('.js-shed-length, .js-shed-width').on('change keyup keydown load', function () {

        var row = $(this).closest('.js-shed-row');

        var l = row.find('.js-shed-length').val();
        var w = row.find('.js-shed-width').val();

        var area = l * w;

        row.find('.js-floor-area').val(area);

    });

    $('body').on('click', '.js-add-shed', function (e) {

        e.preventDefault();

        valid = true;
        var error_msg = "";
        var msg = '';

        var shed = $(this).closest('.js-shed-row');
        var farm_id = $('.js-farm-id').val();

        error_msg += shed.checkRequiredFields();

        if (valid) {
            var post_data = shed.find('input, select, textarea').serialize();
            post_data += "&farm_id=" + farm_id;
            var url = "../php/ajax/shed.php?action=save";

            console.log('post_data', post_data);

            $.post(url, post_data, function (data) {

                try {
                    var response = JSON.parse(data);

                    console.log('response', response);

                    if (response.valid) {
                        //Replenish table
                        console.log('response.html', response.html);
                        $('.js-sheds').html(response.html);
                    } else {
                        valid = false;
                        error_msg += response.error_message;
                        msg = errorify(error_msg);
                        dialogify(msg);
                    }
                } catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    dialogify(msg);
                }

            });

        }

        if (!valid) {
            msg = errorify(error_msg);
            dialogify(msg);
        }


    });

    $('body').on('click', '.js-remove-shed', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        message = "<h2>Are you sure you want to delete this?</h2><p>Any related records will be removed.</p>";

        shed = $(this).closest('.js-shed-row');
        id = shed.attr('data_id');

        options = {
            buttons: {
                'Yes': function () {
                    $(this).dialog("close");
                    post_data = {id: id};
                    url = "../php/ajax/shed.php?action=delete";

                    $.post(url, post_data, function (data) {

                        try {

                            response = JSON.parse(data);

                            console.log(response);

                            if (response.valid) {
                                shed.slideUp();
                                //$(this).dialog("close");
                            } else {
                                valid = false;
                                error_msg += response.error_message;
                                msg = errorify(error_msg);
                                dialogify(msg);
                            }
                        } catch (e) {
                            valid = false;
                            error_msg += "<li>Technical error: " + data + "</li>";
                            msg = errorify(error_msg);
                            dialogify(msg);
                        }

                    });
                },
                'No': function () {
                    $(this).dialog("close");
                },
            }
        };

        dialogify(message, options);

    });

    $('body').on('change keyup keydown', '.js-edit-shed-row input, .js-edit-shed-row textarea', function () {

        valid = true;
        var error_msg = "";
        var msg = '';

        var shed = $(this).closest('.js-shed-row');
        console.log('shed', shed);
        var id = shed.attr('data_id');

        error_msg += shed.checkRequiredFields();

        if (valid) {
            var post_data = shed.find('input, select, textarea').serialize();
            post_data += "&id=" + id;
            var url = "../php/ajax/shed.php?action=save";

            console.log('post_data', post_data);

            $.post(url, post_data, function (data) {

                try {
                    var response = JSON.parse(data);

                    console.log('response', response);

                    if (response.valid) {
                        //Don't replenish table
                    } else {
                        valid = false;
                        error_msg += response.error_message;
                        msg = errorify(error_msg);
                        dialogify(msg);
                    }
                } catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    dialogify(msg);
                }

            });

        }

        if (!valid) {
            msg = errorify(error_msg);
            dialogify(msg);
        }

    })

});
