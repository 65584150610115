$(document).ready(function () {

    $('body').on('change', '.js-cycle-farm', function () {

        var mondays_only = false;

        var farm_type_id;

        farm_type_id = $(this).find('option:selected').attr('data_type-id');

        if (farm_type_id == 1) {
            mondays_only = 1;
        }

        initialiseDatePicker(mondays_only);
    });

    $('.js-use-default-cycle').on('change', function () {
        if ($(this).is(':checked')) {
            $('.js-specify-cycle-days').slideUp();
        } else {
            $('.js-specify-cycle-days').slideDown();
        }
    });

    $('body').on('change', '.js-day-email-notify', function () {

        $('.js-day-email-notify-fields').slideToggle();

        if (!$(this).is(':checked')) {
            $('.js-day-email-notify-fields').find('input').val('');
        }
    });

    $('body').on('click', function (e) {
        /*Close day popup when clicking off it*/
        if ($(e.target).closest('.js-day-popup').length < 1) {
            $('.js-cycle-day').removeClass('popped')
            $('.js-day-popup').remove();
        }
    });

    $('body').on('click', '.js-cycle-day', function (e) {

        if ($(e.target).closest('.js-day-popup').length < 1) {
            var day = $(this);
            var cycle = day.closest('.js-cycle');

            var cycle_id = cycle.attr('data_id');
            var date = day.attr('data_date');

            var valid = true;
            var error_msg = msg = '';

            var post_data = {
                cycle_id: cycle_id,
                date: date
            };

            var url = "../php/ajax/day.php?action=get";

            $.post(url, post_data, function (data) {

                try {

                    var response = JSON.parse(data);

                    $('.js-cycle-day').removeClass('popped')
                    day.addClass('popped');
                    $('.js-day-popup').remove();

                    //Append to screen not day, then position based on day location on screen
                    $('body').append(response.popup);

                    var popup = $('.js-day-popup');

                    var left = day.offset().left + 1;
                    var top = day.offset().top + day.height();

                    popup.css({left: left, top: top});

                    var offscreen = false;

                    if ((popup.offset().left + popup.width()) >= $(window).width()) {
                        offscreen = true;
                    }

                    if (offscreen) {
                        popup.addClass('offscreen');
                    }

                } catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    dialogify(msg);
                }

            });
        }

    });

    $('body').on('change', '.js-filter-farms-by-company', function () {
        var company_id = $(this).val();
        var type_id = $('.js-type-id').val();

        var valid = true;
        var error_msg = msg = '';

        var post_data = {
            company_id: company_id,
            type_id: type_id
        };

        var url = "../php/ajax/farm.php?action=get";

        $.post(url, post_data, function (data) {

            try {

                var response = JSON.parse(data);

                console.log(response);

                $('.js-farm-id-wrapper').html(response.select);

            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = errorify(error_msg);
                dialogify(msg);
            }

        });
    });

    $('body').on('click', '.js-save-cycle', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        form = $(this).closest('form, .js-form');

        error_msg += form.checkRequiredFields();

        if (valid) {

            post_data = form.find('input, select, textarea').not('.js-dont-pass').serialize();

            id = $('.js-cycle-id').val();

            if (typeof id !== 'undefined') {
                message = "<h2>Are you sure you want to edit this?</h2><p>If changing the start date or run days any existing notes will remain on the dates originally added.</p>";

                options = {
                    buttons: {
                        'Yes': function () {
                            saveCycle();
                        },
                        'No': function () {
                            $(this).dialog("close");
                        },
                    }
                };

                dialogify(message, options);
            } else {
                saveCycle();
            }

        }

        if (!valid) {
            msg = errorify(error_msg);
            dialogify(msg);
        }


    });

    function saveCycle() {
        console.log('saveCycle()');
        url = "../php/ajax/cycle.php?action=save";
        console.log('post_data', post_data);
        $.post(url, post_data, function (data) {

            try {

                response = JSON.parse(data);

                console.log(response);

                if (response.valid) {
                    console.log('response', response);

                    if (response.type_id == 1) {
                        location.href = "/cycles/layers";
                    } else {
                        location.href = "/cycles/broilers";
                    }

                } else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = errorify(error_msg);
                    dialogify(msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = errorify(error_msg);
                dialogify(msg);
            }

        });
    }

    $('body').on('click', '.js-delete-cycle', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        message = "<h2>Are you sure you want to delete this?</h2><p>Any related records will be removed.</p>";

        id = $('.js-cycle-id').val();

        if (id == '' || typeof id === 'undefined') {
            id = $(this).closest('.js-cycle').attr('data_id');
        }

        options = {
            buttons: {
                'Yes': function () {
                    post_data = {
                        id: id
                    };
                    url = "../php/ajax/cycle.php?action=delete";

                    $.post(url, post_data, function (data) {

                        try {

                            response = JSON.parse(data);

                            console.log(response);

                            if (response.valid) {
                                if (response.type_id == 1) {
                                    location.href = "/cycles/layers";
                                } else {
                                    location.href = "/cycles/broilers";
                                }
                            } else {
                                valid = false;
                                error_msg += response.error_message;
                                msg = errorify(error_msg);
                                dialogify(msg);
                            }
                        } catch (e) {
                            valid = false;
                            error_msg += "<li>Technical error: " + data + "</li>";
                            msg = errorify(error_msg);
                            dialogify(msg);
                        }

                    });
                },
                'No': function () {
                    $(this).dialog("close");
                },
            }
        };

        dialogify(message, options);

    });

    $('body').on('click', '.js-archive-cycle', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        id = $(this).closest('.js-cycle').attr('data_id');

        post_data = {id: id};
        url = "../php/ajax/cycle.php?action=archive";

        $.post(url, post_data, function (data) {

            try {

                response = JSON.parse(data);
                console.log('response', response);
                if (response.valid) {
                    if (response.type_id == 1) {
                        location.href = "/cycles/layers";
                    } else {
                        location.href = "/cycles/broilers";
                    }
                } else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = errorify(error_msg);
                    dialogify(msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = errorify(error_msg);
                dialogify(msg);
            }

        });


    });

    $('body').on('click', '.js-unarchive-cycle', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        id = $(this).closest('.js-cycle').attr('data_id');

        post_data = {id: id};
        url = "../php/ajax/cycle.php?action=unarchive";

        console.log('id', id);

        $.post(url, post_data, function (data) {

            try {

                response = JSON.parse(data);

                console.log(response);

                if (response.valid) {
                    location.href = "/crop-cycles";
                } else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = errorify(error_msg);
                    dialogify(msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = errorify(error_msg);
                dialogify(msg);
            }

        });

    });

    $('body').on('change', '.js-cycle-filter', function () {
        var field = $(this).attr('name');
        var value = $(this).val();

        filterCycles(field, value);
    });

    function filterCycles(field, value) {

        console.log('filterCycles()');

        var post_data = {
            field: field,
            value: value
        };

        var url = "../php/ajax/cycle.php?action=filter";

        $.post(url, post_data, function (data) {

            try {

                var response = JSON.parse(data);

                console.log('response', response);

                $('.js-crop-cycles').html(response.html);
                rejig();

            } catch (e) {
                dialogify("<li>Technical error: " + data + "</li>");
            }

        });
    }

    $('body').on('click', '.js-save-day', function (e) {

        e.preventDefault();

        var valid = true;
        var error_msg = "";

        var form = $(this).closest('.js-day-popup');

        console.log('popup', form);

        error_msg += form.checkRequiredFields();

        if (valid) {
            var post_data = form.find('input, select, textarea').serialize();

            var cycle_day = $('.js-cycle-day.popped');
            var cycle = cycle_day.closest('.js-cycle');
            var date = cycle_day.attr('data_date');
            var cycle_id = cycle.attr('data_id');

            console.log('cycle', cycle);
            console.log('date', date);
            console.log('cycle_id', cycle_id);

            post_data += '&date=' + date + '&cycle_id=' + cycle_id;

            console.log('post_data', post_data);

            var url = "../php/ajax/cycle.php?action=save-day";

            $.post(url, post_data, function (data) {

                try {

                    console.log('data', data);

                    var response = JSON.parse(data);

                    console.log('response', response);

                    if (response.valid) {
                        $('.js-cycle-day').removeClass('popped')
                        $('.js-day-popup').remove();
                        filterCycles('', '');
                        rejig();
                        setTimeout(rejig, 100);
                        setTimeout(rejig, 500);
                        setTimeout(rejig, 1000);
                    } else {
                        valid = false;
                        error_msg += response.error_message;
                        msg = errorify(error_msg);
                        dialogify(msg);
                    }
                } catch (e) {
                    console.log('e', e);
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    dialogify(msg);
                }

            }).done(rejig());

        }

        if (!valid) {
            msg = errorify(error_msg);
            dialogify(msg);
        }


    });

    $('body').on('click', '.js-clear-filters', function () {

        filterCycles('clear', 'all');
        $('select.js-cycle-filter').prop('selectedIndex', 0);
        $('.js-select-box').select2();
        location.reload();
    });
});

function rejig() {
    //Make elements all same width
    var max_w = 0;
    $('.js-same-width').each(function () {
        if ($(this).width() > max_w) {
            max_w = $(this).width();
        }
    });
    $('.js-same-width').width(max_w);

    //Make cycles match height
    /*var max_h = 0;
    $('.js-cycle, .js-cycle-day').each(function () {
        if ($(this).height() > max_h) {
            max_h = $(this).height();
        }
    });
    $('.js-cycle').height(max_h);*/

    //Set up Today line
    if ($('.js-today').length > 0) {
        var left = $('.js-today').position().left;
        var width = $('.js-today').width();
        left = left + (width / 2) - 1;

        var height = 0;

        $('.js-cycle').each(function () {
            height += $(this).height()
        });

        $('.js-today-line').css({left: left, height: height});
    } else {
        $('.js-today-line').hide();
    }


}


$(window).on('ready load resize orientationchange', function () {
    rejig();
});
