$(document).ready(function () {

    $('body').on('change','.js-farm-cycle-status',  function () {

        var valid = true;
        var error_msg = msg = '';

        var farm_id = $('.js-farm-id').val();
        var day = $(this).closest('.js-farm-day');
        var day_number = day.attr("data_day-number");
        var status_id = $(this).val();

        var post_data = {
            farm_id: farm_id,
            day_number: day_number,
            status_id: status_id
        };

        console.log('post_data', post_data);

        var url = "../php/ajax/farm.php?action=update-cycle-day-status";

        $.post(url, post_data, function (data) {

            try {
                var response = JSON.parse(data);

                console.log('response', response);

                if (response.valid) {

                } else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = errorify(error_msg);
                    dialogify(msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = errorify(error_msg);
                dialogify(msg);
            }

        });
    });

    $('.js-farm-cycle-run-days').on('change', function () {

        var days = $(this).val();
        var farm_id = $('.js-farm-id').val();

        var valid = true;
        var error_msg = msg = '';

        var post_data = {
            days: days,
            farm_id: farm_id
        };

        var url = "../php/ajax/farm.php?action=update-cycle-days";

        $.post(url, post_data, function (data) {

            try {
                var response = JSON.parse(data);

                console.log('response', response);

                if (response.valid) {
                    $('.js-farm-cycle-days').html(response.html);
                } else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = errorify(error_msg);
                    dialogify(msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = errorify(error_msg);
                dialogify(msg);
            }

        });

    });

    $('.js-save-farm').on('click', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        form = $(this).closest('form, .js-form');

        error_msg += form.checkRequiredFields();

        if (valid) {
            post_data = form.find('input, select, textarea').not('.js-sheds input, .js-sheds textarea, .js-farm-cycle-days select, .js-farm-cycle-days input').serialize();

            url = "../php/ajax/farm.php?action=save";

            $.post(url, post_data, function (data) {

                try {

                    response = JSON.parse(data);

                    console.log(response);

                    if (response.valid) {
                        location.href = "/farms";
                    } else {
                        valid = false;
                        error_msg += response.error_message;
                        msg = errorify(error_msg);
                        dialogify(msg);
                    }
                } catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    dialogify(msg);
                }

            });

        }

        if (!valid) {
            msg = errorify(error_msg);
            dialogify(msg);
        }


    });

    $('.js-edit-farm').on('click', function (e) {
        e.preventDefault();
        id = $(this).attr('data_id');
        location.href = '/farm/' + id;
    });

    $('.js-delete-farm').on('click', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        message = "<h2>Are you sure you want to delete this?</h2><p>Any related records will be removed.</p>";

        id = $('.js-farm-id').val();

        options = {
            buttons: {
                'Yes': function () {
                    //$(this).dialog("close");
                    post_data = {id: id};
                    url = "../php/ajax/farm.php?action=delete";

                    $.post(url, post_data, function (data) {

                        try {

                            response = JSON.parse(data);

                            console.log(response);

                            if (response.valid) {
                                location.href = "/farms";
                            } else {
                                valid = false;
                                error_msg += response.error_message;
                                msg = errorify(error_msg);
                                dialogify(msg);
                            }
                        } catch (e) {
                            valid = false;
                            error_msg += "<li>Technical error: " + data + "</li>";
                            msg = errorify(error_msg);
                            dialogify(msg);
                        }

                    });
                },
                'No': function () {
                    $(this).dialog("close");
                },
            }
        };

        dialogify(message, options);

    });

});
